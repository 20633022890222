<template>
  <div class="leftbox">
    <div class="oderbox" v-if="this.optionid === 32 || this.optionid === 33">
      <div class="btnbox" v-if="this.optionid === 32">
        <TitleBox :titlename="'雷达覆盖范围'" />
        <div class="colorbox">
          <div class="demo">
            <MyLottie />
            <p class="pbtn" @click="opclose()">{{textshow?"全 关":"全 开"}}</p>
          </div>

        </div>
        <!-- <el-button  size="mini" type="primary" >全关</el-button> -->
      </div>
      <div class="btnbox" v-if="this.optionid === 33">
        <TitleBox :titlename="'监控可视域范围'" />
        <div class="colorbox">
          <div class="demo">
            <MyLottie />
            <p class="pbtn" @click="videoclose()">{{videoshow?"全 关":"全 开"}}</p>
          </div>
        </div>
        <!-- <el-button  size="mini" type="primary" >全关</el-button> -->
      </div>
      <div class="left-record">
        <TitleBox :titlename="'本年告警统计'" />
        <div class="statistical">
          <div class="imgbox">
            <p>{{yearNumber}}</p>
            <img src="../../assets/homeimg/icon4.png" alt="">
            <p class="p2">本年告警总数</p>
          </div>
          <div class="box">
            <div class="numbox">
              <p>{{dayNumber}}</p>
              <span>今日告警次数</span>
            </div>
            <div class="numbox">
              <p>{{monthNumber}}</p>
              <span>本月告警次数</span>
            </div>
          </div>
        </div>
      </div>
      <div class="left-recordtwo">
        <div class="tabbox">
          <ul>
            <li>
              <p>时间</p>
              <p>人员</p>
              <p>区域</p>
              <p>类型</p>
            </li>
            <li v-for="(item,index) in tableData2" :key="index">
              <p>{{item.data}}</p>
              <p>{{item.name}}</p>
              <p>{{item.radarCoordinates + "-" + item.szName}}</p>
              <p>{{item.wfType}}</p>

            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="waterEnbox" v-if="this.optionid === 34">
      <div class="left-record">
        <TitleBox :titlename="'水质监测统计'" />
        <div class="tabbox">
          <div class="libox">
            <p>设备</p>
            <p>水温</p>
            <p>PH值</p>
            <p>电导率</p>
            <p>溶解氧</p>
            <p>浊度</p>
          </div>
          <ul>
            <li v-for="(item,index) in tableData" :key="index">
              <p class="pcolor">{{item.devname}}</p>
              <p :class="[item.temp < 4 && item.temp > 32 ? 'police':'']">{{item.temp}}</p>
              <p :class="[item.ph < 6 && item.ph > 9 ? 'police':'']">{{item.ph}}</p>
              <p :class="[item.ct > 1 ? 'police':'']">{{item.ct}}</p>
              <p :class="[item.ddo < 3 ? 'police':'']">{{item.ddo}}</p>
              <p :class="[item.tur > 70 ? 'police':'']">{{item.tur}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="left-record">
        <TitleBox :titlename="'水文监测统计'" />
        <div class="tabbox">
          <div class="libox">
            <p>设备</p>
            <p>流速</p>
            <p>水深</p>
            <p>流量</p>
          </div>
          <ul>
            <li v-for="(item,index) in tableData1" :key="index" @click="gotoodr()">
              <p class="pcolor">{{item.devName}}</p>
              <p>{{item.speed}}</p>
              <p :class="[item.height < 2 ? 'police':'']">{{item.height}}</p>
              <p>{{item.flow}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="left-record">
        <TitleBox :titlename="'气象监测统计'" />
        <div class="tabbox">
          <div class="libox">
            <p>设备</p>
            <p>温度</p>
            <p>风向</p>
            <p>风速</p>
            <p>降水量</p>
            <p>湿度</p>
            <p>气压</p>
          </div>
          <ul>

            <li v-for="(item,index) in tableData3" :key="index">
              <p class="pcolor">{{item.devName}}</p>
              <p :class="[item.temp < -10 && item.temp > 40 ? 'police':'']">{{item.temp}}</p>
              <p>{{item.winddirectiondes}}</p>
              <p :class="[item.windSpeed > 14 ? 'police':'']">{{item.windSpeed}}</p>
              <p>{{item.rainfall}}</p>
              <p>{{item.humidity}}</p>
              <p>{{item.atmosphere}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="waterEnbox" v-if="this.optionid === 35">
      <div class="left-acoustic">
        <TitleBox :titlename="'被动声学监测统计'" />
        <div class="tabbox">
          <el-table :data="acousticlist" style="width: 120%;color:#BFDAFD"
            :header-cell-style="{background:'transparent', color:'#fff'}"
            :row-class-name="tableRowClassName" size="small">

            <el-table-column prop="drname" label="设备" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="type" label="事件名称" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="timestamp" label="发生时间" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <p @click="openimg(scope.row)" style="color:#6EB6FB">查看
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="left-acoustic">
        <TitleBox :titlename="'主动声学监测统计'" />
        <div class="tabbox">
          <el-table :data="fishOutlist" style="width: 120%;color:#BFDAFD"
            :header-cell-style="{background:'transparent', color:'#fff'}"
            :row-class-name="tableRowClassName" size="small">

            <el-table-column prop="videoTime" label="时间" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="devName" label="名称" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <p @click="openvideo(scope.row)" style="color:#6EB6FB">查看
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import api from '../visualization/api'
import axios from 'axios'
import TitleBox from '../visualization/components/title/index.vue'
import MyLottie from '../dialog/MyLottie.vue'
export default {
  props: ['op', 'equipmentid', 'radarnum'],
  components: {
    TitleBox,
    MyLottie
  },
  data () {
    return {
      optionid: 32,
      tableData2: [],
      tableData: [],
      tableData1: [],
      tableData3: [],
      curPageNum: 1,
      curRecordNum: 20,
      dayNumber: null,
      monthNumber: null,
      yearNumber: null,
      acousticlist: [],
      fishOutlist: [],
      textshow: false,
      videoshow: false
    }
  },
  computed: {

  },
  watch: {
    equipmentid: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.optionid = newvalue
          // this.ifoptionid()
          // console.log(this.optionid, 'zizujianop')
        })
      }
    },
    op: {
      // immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.op = newvalue
          // console.log(this.op, 'zizujianop')
        })
      }
    },
    radarnum: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          if (newvalue !== 0) {
            // this.opclose(1)
            this.op.GetEquipmentHelper().Controlsurfaces(false)
            this.textshow = false
            this.op.GetEquipmentHelper().VisibleRange(false)
            this.videoshow = false
          }
        })
      }
    }
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      return 'success-row'
    },
    opclose () {
      if (this.textshow) {
        this.op.GetEquipmentHelper().Controlsurfaces(false)
      } else {
        this.op.GetEquipmentHelper().Controlsurfaces(true)
      }
      this.textshow = !this.textshow
    },
    videoclose () {
      if (this.videoshow) {
        this.op.GetEquipmentHelper().VisibleRange(false)
      } else {
        this.op.GetEquipmentHelper().VisibleRange(true)
      }
      this.videoshow = !this.videoshow
    },
    openimg (val) {
      this.$emit('openimg', val)
    },
    openvideo (val) {
      this.$emit('openvideo', val)
    },
    pageacoustic () {
      const obj = {
        pageSize: 300,
        pageSum: 1,
        type: null
      }
      axios.post(this.$constant.zsqyUrl + '/zsqy/waterincident/findPageWaterinciddent', obj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res')
          this.acousticlist = res.data.data.records
        })
    },
    pagefindPageAll () {
      const obj = {
        pageSize: 50,
        pageSum: 1,
        endTime: '',
        startTime: ''
      }
      axios.post(this.$constant.zsqyUrl + '/zsqy/VideoFile/findPageAll', obj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res')
          this.fishOutlist = res.data.data.records
        })
    },
    pagestatistical () {
      axios.get(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findRadarAlarmsVO',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'ressss')
          this.yearNumber = res.data.data.yearNumberOfAlarms
          this.monthNumber = res.data.data.monthNumberOfAlarms
          this.dayNumber = res.data.data.dayNumberOfAlarms
          this.tableData2 = res.data.data.dayListAlarms
          // console.log(this.tableData2, '-=')
        })
    },
    WaterqualityPage () {
      const obj = {
        current: 1,
        ids: [],
        size: 20
      }
      axios.post(this.$constant.zsqyUrl + '/Waterquality/findByPage', obj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res.data, 'res111111111111111111111')
          this.tableData = res.data.data.records
          // console.log(this.tableData[this.tableData.length - 1], '9090')
          this.$store.commit('zsqy/setWaterQuality', this.tableData[0])
        })
      const meteorologicalobj = {
        pageSize: 20,
        pageSum: 1
      }
      axios.post(this.$constant.zsqyUrl + '/zsqy/WaterWeather/findPageAll', meteorologicalobj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res1')
          this.tableData3 = res.data.data.records
          // console.log(this.tableData[this.tableData.length - 1], '9090')
          this.$store.commit('zsqy/setmeteorological', this.tableData3[0])
        })
      axios.post(this.$constant.zsqyUrl + '/zsqy/WaterHydrology/findPageAll', meteorologicalobj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res1')
          this.tableData1 = res.data.data.records
          // console.log(this.tableData[this.tableData.length - 1], '9090')
          this.$store.commit('zsqy/sethydrological', this.tableData1[0])
        })
    }
  },
  created () {
    // console.log(this.op, '子组件op')
    this.WaterqualityPage()
    this.pagestatistical()
    this.pageacoustic()
    this.pagefindPageAll()
  }
}
</script>
<style>
.el-table .success-row {
  background: transparent !important;
}
/*最外层透明*/
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  background-color: transparent;
}
</style>
<style lang="less" scoped>
.leftbox {
  width: 100%;
  height: 100%;
  padding: 20px;
  .echarts {
    width: 100%;
    height: 100%;
  }
  .oderbox {
    width: 100%;
    height: 100%;
    .btnbox {
      width: 100%;
      height: 20%;
      .colorbox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .demo {
          text-align: center;
          margin-top: 5%;
          width: 30%;
          height: 60%;
          position: relative;
          cursor: pointer;
          p {
            position: absolute;
            top: 35%;
            left: 0;
            width: 100%;
            height: 100%;
            color: white;
            font-size: 20px;
          }
        }

        .btn-grad {
          width: 30%;
          height: 60%;
          background-image: linear-gradient(
            to right,
            #4cb8c4 0%,
            #3cd3ad 51%,
            #4cb8c4 100%
          );
          margin: 10px;
          text-align: center;
          text-transform: uppercase;
          transition: 0.5s;
          background-size: 200% auto;
          color: white;
          box-shadow: 0 0 20px #eee;
          border-radius: 50%;
          display: block;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            width: 100%;
          }
        }

        .btn-grad:hover {
          background-position: right center; /* change the direction of the change here */
          color: #fff;
          text-decoration: none;
        }
      }
      // background: transparent;
    }
    .left-record {
      width: 100%;
      height: 30%;

      ul li {
        margin: 0;
        padding: 0;
      }
      .statistical {
        //   background: #fff;
        width: 100%;
        height: 94%;
        display: flex;
        justify-content: space-between;
        .imgbox {
          width: 49%;
          height: 100%;
          font-size: 26px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            width: 100%;
            height: 30px;
            text-align: center;
          }
          img {
            width: 80px;
            height: 119px;
          }
          .p2 {
            font-size: 10px;
          }
        }
        .box {
          // background: #80ffca;
          width: 49%;
          height: 100%;
          overflow: hidden;
          .numbox {
            width: 100%;
            height: 28%;
            margin-top: 10%;
            background: rgba(255, 246, 98, 0.3);
            border-left: 3px solid rgba(255, 246, 98, 1);
            color: rgba(255, 246, 98, 1);
            font-size: 28px;
            font-family: SourceHanSansCN;
            font-weight: 500;
            overflow: hidden;
            text-align: center;
            span {
              font-size: 10px;
              color: #fff;
              display: block;
              margin-top: -18%;
            }
          }
          .numbox:nth-child(2) {
            background: rgba(255, 10, 10, 0.3);
            border-left: 3px solid rgba(255, 10, 10, 1);
            color: #df4646;
          }
        }
      }
      .tabbox {
        width: 100%;
        height: 100%;
        // margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            //   align-items: center;
            line-height: 40px;
            text-align: center;
            // background: RGBA(56, 74, 97, 0.2);
            p {
              width: 33%;
              color: #fff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
    }
    .left-recordtwo {
      width: 100%;
      height: 45%;

      ul li {
        margin: 0;
        padding: 0;
      }
      .tabbox {
        width: 100%;
        height: 100%;
        // margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            //   align-items: center;
            line-height: 40px;
            text-align: center;
            // background: RGBA(56, 74, 97, 0.2);
            p {
              width: 33%;
              color: #fff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  .waterEnbox {
    width: 100%;
    height: 100%;
    .left-acoustic {
      width: 100%;
      height: 45%;
      // position: relative;
      ul li {
        margin: 0;
        padding: 0;
      }

      .tabbox {
        width: 100%;
        height: 95%;
        margin-top: 15px;
        overflow-y: auto;
        /deep/ .el-table tbody tr:hover > td {
          background-color: rgba(79, 119, 163, 0.4);
        } //鼠标移入表单背景颜色
        /deep/.el-table td,
        .el-table th.is-leaf {
          border-bottom: 1px solid transparent !important;
          text-align: center;
          font-size: 10px;
          color: #fff;
          // position: relative;
          p {
            margin: 0 auto;
          }
        } //el table表单下边框颜色
        /deep/.el-table--border:after,
        .el-table--group:after,
        .el-table:before {
          background-color: transparent;
        } //el table表单最下面下边框颜色
        /deep/.el-table th.is-leaf {
          border-bottom: 1px solid #133e47;
          color: #fff;
          font-size: 10px;
          text-align: center;
        } //表头下边框颜色
        /* 设置滚动条的样式 */
        // ::-webkit-scrollbar {
        //   width: 8px;
        //   height: 8px;
        //   background: RGBA(56, 74, 97, 0.2);
        // }
        // /* 滚动条滑块 */
        // ::-webkit-scrollbar-thumb {
        //   border-radius: 10px;
        //   background: RGBA(56, 74, 97, 0.6);
        //   -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        // }
        // .libox {
        //   width: 120%;
        //   height: 40px;
        //   // background: #e6fcff;
        //   display: flex;
        //   // justify-content: space-between;
        //   p {
        //     width: 23%;
        //     color: #fff;
        //     text-align: center;
        //   }
        //   p:nth-child(3) {
        //     width: 30%;
        //   }
        // }
        // ul {
        //   width: 102%;
        //   height: 84%;

        //   li {
        //     width: 100%;
        //     height: 40px;
        //     display: flex;
        //     // justify-content: space-between;
        //     // align-items: center;
        //     line-height: 40px;
        //     // background: RGBA(56, 74, 97, 0.2);
        //     p {
        //       width: 23%;
        //       color: #fff;
        //       overflow: hidden;
        //       white-space: nowrap;
        //       text-align: center;
        //       text-overflow: ellipsis;
        //       -o-text-overflow: ellipsis;
        //       font-size: 10px;
        //       font-family: SourceHanSansCN;
        //       font-weight: 300;
        //     }
        //     p:nth-child(3) {
        //       width: 30%;
        //     }
        //   }
        //   li:nth-child(2n) {
        //     background: RGBA(52, 75, 87, 0.6);
        //   }
        //   li:hover {
        //     background: rgba(79, 119, 163, 0.4);
        //   }
        // }
      }
      /* 设置滚动条的样式 */
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: RGBA(56, 74, 97, 0.2);
      }
      /* 滚动条滑块 */
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: RGBA(56, 74, 97, 0.6);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }
    }
    .left-acoustic:nth-child(2) {
      margin-top: 10%;
    }
    .left-record {
      width: 100%;
      height: 28%;
      position: relative;
      ul li {
        margin: 0;
        padding: 0;
      }

      .tabbox {
        width: 100%;
        height: 73%;
        margin-top: 15px;
        .libox {
          width: 98%;
          height: 40px;
          // background: #e6fcff;
          display: flex;
          // justify-content: space-between;
          p {
            width: 17%;
            color: #fff;
            text-align: center;
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // -o-text-overflow: ellipsis;
          }
        }
        ul {
          width: 100%;
          height: 84%;
          overflow-y: auto;
          li {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            // align-items: center;
            line-height: 40px;
            // background: RGBA(56, 74, 97, 0.2);
            p {
              width: 17%;
              color: #80ffca;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
              font-size: 10px;
              font-family: SourceHanSansCN;
              font-weight: 300;
              text-align: center;
            }
            .pcolor {
              color: #fff;
            }
            .police {
              color: #fff558;
            }
          }
          li:nth-child(2n) {
            background: RGBA(52, 75, 87, 0.6);
          }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
      .btnbox {
        width: 50%;
        height: 40px;
        position: absolute;
        top: 0%;
        right: 1%;
      }
    }
    .left-record:nth-child(1) {
      height: 40%;
    }
    .left-record:nth-child(2) {
      .libox {
        height: 40px;
        // background: #e6fcff;
        display: flex;
        justify-content: space-between;
        p {
          // width: 20%;
          color: #fff;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // -o-text-overflow: ellipsis;
        }
      }
    }
    .left-record:nth-child(3) {
      p {
        width: 25%;
      }
      // height: 35%;
    }
  }
}
</style>
<style>
