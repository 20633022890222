<template>
  <div class="content">

    <div id="RenderCanvas"></div>
    <!-- 左侧栏 -->
    <div :class="[
      leftOutAnimation ? 'leftOutAnim' : '',
      leftInAnimation ? 'leftInAnim' : '',
      'leftBox',
    ]">
      <div class="contentbox">
        <leftbox />
      </div>
    </div>
    <!-- 首页菜单 -->
    <div :class="[
      footerOutAnimation ? 'footerOutAnim' : '',
      footerInAnimation ? 'footerInAnim' : '',
      'menubox',
    ]" v-if="listshow">
      <div class="switchbox">
        <div class="switchtop">
          <div class="minbox" v-if="footerInAnimation" @click="animationClose">
            <div class="imgbox">
              <img src="../../assets/homeimg/c1.png" alt="">
            </div>
            <p>收起两侧</p>
          </div>
          <div class="minbox" v-if="footerOutAnimation" @click="handleShow">
            <div class="imgbox">
              <img src="../../assets/homeimg/c7.png" alt="">
            </div>
            <p>展开两侧</p>
          </div>
          <div class="minbox" v-for="(itme, index) in witchtoplist" :key="index" @click="witchtopbtn(index)">
            <div class="imgbox">
              <img :src="itme.img" alt="">
            </div>
            <p>{{ itme.name }}</p>
          </div>
          <div class="minbox">
            <div class="imgbox">
              <a-switch checkedChildren="3D" unCheckedChildren="2D" :defaultChecked="false" @click="isSwitch" />
            </div>
            <p>三维切换</p>
          </div>
          <div class="minbox" v-if="terrain">
            <div class="imgbox">
              <a-switch checkedChildren="开" unCheckedChildren="关" :defaultChecked="false" @click="istype" />
            </div>
            <p>地形切换</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 设备菜单控制 -->
    <div :class="[
      odermenuOutAnimation ? 'footerOutAnim' : '',
      odermenuInAnimation ? 'footerInAnim' : '',
      'menubox',
    ]" v-if="odermenulistshow">
      <div class="switchbox">
        <div class="switchtop">
          <div class="minbox" v-if="odermenuInAnimation" @click="odermenulistClose">
            <div class="imgbox">
              <img src="../../assets/homeimg/c1.png" alt="">
            </div>
            <p>收起两侧</p>
          </div>
          <div class="minbox" v-if="odermenuOutAnimation" @click="odermenulistOpen">
            <div class="imgbox">
              <img src="../../assets/homeimg/c7.png" alt="">
            </div>
            <p>展开两侧</p>
          </div>
          <div class="minbox" v-for="(itme, index) in witchtoplist" :key="index" @click="witchtopbtn(index)">
            <div class="imgbox">
              <img :src="itme.img" alt="">
            </div>
            <p>{{ itme.name }}</p>
          </div>
          <div class="minbox">
            <div class="imgbox">
              <a-switch checkedChildren="3D" unCheckedChildren="2D" :defaultChecked="false" @click="isSwitch" />
            </div>
            <p>三维切换</p>
          </div>
          <div class="minbox" v-if="terrain">
            <div class="imgbox">
              <a-switch checkedChildren="开" unCheckedChildren="关" :defaultChecked="false" @click="istype" />
            </div>
            <p>地形切换</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 图例菜单控制 -->
    <div :class="[
      diagramOutAnimation ? 'footerOutAnim' : '',
      diagramInAnimation ? 'footerInAnim' : '',
      'menubox',
    ]" v-if="tulishow">
      <div class="switchbox">
        <div class="switchtop">
          <div class="minbox" v-if="diagramInAnimation" @click="diagramClosed">
            <div class="imgbox">
              <img src="../../assets/homeimg/c1.png" alt="">
            </div>
            <p>收起两侧</p>
          </div>
          <div class="minbox" v-if="diagramOutAnimation" @click="diagramOpen">
            <div class="imgbox">
              <img src="../../assets/homeimg/c7.png" alt="">
            </div>
            <p>展开两侧</p>
          </div>
          <div class="minbox" v-for="(itme, index) in witchtoplist" :key="index" @click="witchtopbtn(index)">
            <div class="imgbox">
              <img :src="itme.img" alt="">
            </div>
            <p>{{ itme.name }}</p>
          </div>
          <div class="minbox">
            <div class="imgbox">
              <a-switch checkedChildren="3D" unCheckedChildren="2D" :defaultChecked="false" @click="isSwitch" />
            </div>
            <p>三维切换</p>
          </div>
          <div class="minbox" v-if="terrain">
            <div class="imgbox">
              <a-switch checkedChildren="开" unCheckedChildren="关" :defaultChecked="false" @click="istype" />
            </div>
            <p>地形切换</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 环境菜单控制 -->
    <div :class="[
      environmentOutAnimation ? 'footerOutAnim' : '',
      environmentInAnimation ? 'footerInAnim' : '',
      'menubox',
    ]" v-if="huanjinshow">
      <div class="switchbox">
        <div class="switchtop">
          <div class="minbox" v-if="environmentInAnimation" @click="environmentClosed">
            <div class="imgbox">
              <img src="../../assets/homeimg/c1.png" alt="">
            </div>
            <p>收起两侧</p>
          </div>
          <div class="minbox" v-if="environmentOutAnimation" @click="environmentOpen">
            <div class="imgbox">
              <img src="../../assets/homeimg/c7.png" alt="">
            </div>
            <p>展开两侧</p>
          </div>
          <div class="minbox" v-for="(itme, index) in witchtoplist" :key="index" @click="witchtopbtn(index)">
            <div class="imgbox">
              <img :src="itme.img" alt="">
            </div>
            <p>{{ itme.name }}</p>
          </div>
          <div class="minbox">
            <div class="imgbox">
              <a-switch checkedChildren="3D" unCheckedChildren="2D" :defaultChecked="false" @click="isSwitch" />
            </div>
            <p>三维切换</p>
          </div>
          <div class="minbox" v-if="terrain">
            <div class="imgbox">
              <a-switch checkedChildren="开" unCheckedChildren="关" :defaultChecked="false" @click="istype" />
            </div>
            <p>地形切换</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 巡护菜单控制 -->
    <div :class="[
      droneOutAnimation ? 'footerOutAnim' : '',
      droneInAnimation ? 'footerInAnim' : '',
      'menubox',
    ]" v-if="xunhushow">
      <div class="switchbox">
        <div class="switchtop">
          <div class="minbox" v-if="droneInAnimation" @click="droneClosed">
            <div class="imgbox">
              <img src="../../assets/homeimg/c1.png" alt="">
            </div>
            <p>收起两侧</p>
          </div>
          <div class="minbox" v-if="droneOutAnimation" @click="droneOpen">
            <div class="imgbox">
              <img src="../../assets/homeimg/c7.png" alt="">
            </div>
            <p>展开两侧</p>
          </div>
          <div class="minbox" v-for="(itme, index) in witchtoplist" :key="index" @click="witchtopbtn(index)">
            <div class="imgbox">
              <img :src="itme.img" alt="">
            </div>
            <p>{{ itme.name }}</p>
          </div>
          <div class="minbox">
            <div class="imgbox">
              <a-switch checkedChildren="3D" unCheckedChildren="2D" :defaultChecked="false" @click="isSwitch" />
            </div>
            <p>三维切换</p>
          </div>
          <div class="minbox" v-if="terrain">
            <div class="imgbox">
              <a-switch checkedChildren="开" unCheckedChildren="关" :defaultChecked="false" @click="istype" />
            </div>
            <p>地形切换</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧栏 -->
    <div :class="[
      leftOutAnimation ? 'rightOutAnim' : '',
      leftInAnimation ? 'rightInAnim' : '',
      'rightBox',
    ]">
      <div class="contentbox">
        <rightbox :env="env" :radarnum="radarnum" />
      </div>
    </div>
    <!-- 底部侧栏 -->
    <div :class="[
      bottomOutAnimation ? 'bottomOutAnim' : '',
      bottomInAnimation ? 'bottomInAnim' : '',
      'bottomBox',
    ]" @mouseleave="leaveshowfalse">
      <div class="navigation">
        <div :class="{ action: footerAction == index }" v-for="(item, index) in footerlist" :key="index"
          @click="footerbtn(index, item)">
          <!-- {{item}} -->
          <p>{{ item.menuName }}</p>
        </div>

      </div>
      <div class="erjinav" ref="weiyi" v-show="twonavshow">
        <ul>
          <li v-for="(item, index) in architecturelist" :key="index" :class="{ active: curnaverji == item.id }"
            @click="dianjifal(item, index)">
            <p>{{ item.menuName }}</p>
          </li>
        </ul>
      </div>
      <div class="imgBox" @click="gotohome()">
        <img src="../../assets/homeimg/2398.png" alt="" />
        <div class="dh">
          <div class="loader">
            <div class="face">
              <div class="circle"></div>
            </div>
          </div>
        </div>
        <div class="donghuo"></div>
      </div>
    </div>
    <!-- 图裂信息侧栏 -->
    <div :class="[
      diagramOutAnimation ? 'leftOutAnim' : '',
      diagramInAnimation ? 'leftInAnim' : '',
      'sidebarBox',
    ]">
      <div class="contentbox">
        <diagram :op="op" :treeparentid="treeparentid" :radarnum="radarnum" />
      </div>
    </div>
    <!-- 环境监测侧栏 -->
    <div :class="[
      environmentOutAnimation ? 'leftOutAnim' : '',
      environmentInAnimation ? 'leftInAnim' : '',
      'sidebarBox',
    ]">
      <div class="contentbox">
        <environmentSidebar :environmentid="environmentid" :op="op" />
      </div>
    </div>
    <!-- 设备监控侧栏 -->
    <div :class="[
      equipmentOutAnimation ? 'leftOutAnim' : '',
      equipmentInAnimation ? 'leftInAnim' : '',
      'sidebarBox',
    ]">
      <equipmentSidebar :equipmentlist="equirightlist" @changeequipment="changeequipment" :equipmentid="equipmentid"
        :architecturelist="architecturelist" @aquaticbtn='aquaticbtn' />

    </div>
    <!-- 设备监控右侧侧栏 -->
    <div :class="[
      equipmentrightOutAnimation ? 'rightOutAnim' : '',
      equipmentrightInAnimation ? 'rightInAnim' : '',
      'rightsidebarBox',
    ]">
      <equipmentrightSidebar :equipmentid="equipmentid" :op="op" :radarnum="radarnum" @openimg="openimg"
        @openvideo='openvideo' />
    </div>
    <!-- 无人机侧栏 -->
    <div :class="[
      droneOutAnimation ? 'leftOutAnim' : '',
      droneInAnimation ? 'leftInAnim' : '',
      'sidebarBox',
    ]">
      <div class="contentbox">
        <droneSidebar @clickdronelist="clickdronelist" @clickpatrollist='clickpatrollist' :timenum="timenum"
          :dorshow="dorshow" />
      </div>
    </div>
    <!-- 无人机视频弹窗 -->
    <div id="droneboxs" v-if="droneshow" v-drag :style="dronestyle">
      <dilogvideo @cancel="dilogvideoclose(1)" :dronename='dronename' :snC0de='snC0de' :dronevideoUrl="dronevideoUrl" />
    </div>
    <div v-if="environmentInAnimation" class="gotoenvironmentbox" @click="gotoenvironment()">
      <p> <i class="el-icon-s-marketing"></i> 环境监测统计</p>
    </div>
    <!-- 监控视频弹窗 -->
    <div class="debox" v-drag v-if="dilogvideoshow">
      <dilogvideo @cancel="dilogvideoclose" />
    </div>

    <!-- m3u8 视频格式 -->
    <div class="debox" v-drag v-if="dilogm3u8videoshow">
      <m3u8video @close="closem3u8Video" :m3u8videourl="m3u8videourl" :m3u8mdcode="m3u8mdcode"
        :m3u8videoname="m3u8videoname" :m3u8show="m3u8show" />
    </div>
    <!-- 主动声学 -->
    <div class="debox" v-drag v-if="activeAcousticshow">
      <div class="debox_video">
        <m3u8video @close="closem3u8Video" :m3u8videourl="m3u8videourl" :m3u8mdcode="m3u8mdcode"
            :m3u8videoname="m3u8videoname" :m3u8show="m3u8show" />
      </div>
      <div class="debox_list">
        <div class="tabbox">
          <ul>
            <li>
              <p>时间</p>
              <p>大鱼群</p>
              <p>小鱼群</p>
            </li>
            <li v-for="(item,index) in activeAcousticlist" :key="index">
              <p>{{item.createTime}}</p>
              <p>{{item.bigFishCount}}</p>
              <p>{{item.smallFishCount}}</p>

            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 鱼探视频格式 -->
    <div class="debox" v-drag v-if="fishOutshow">
      <fishOut @close="fishOutVideo" :fishOuturl="fishOuturl" :fishOutname="fishOutname" />
    </div>
    <!-- 雷达弹窗 -->
    <radar v-if="radarshow" v-drag @cancel="radarclose" :vncurl='vncurl' :sp_url="sp_url" :m3u8videoname="m3u8videoname"
      :sp_MDcode="sp_MDcode" :m3u8show='m3u8show' />
    <div class="coordinates">
      <p>经度:{{ xcoordinates }}</p>
      <p>纬度:{{ ycoordinates }}</p>
    </div>
    <div class="searchbox" v-if="searchboxshow">
      <el-input v-model="placeNames" placeholder="请输入地名，例如“洪湖/老湾/嘉鱼等"></el-input>
      <img src="../../assets/homeimg/c8.png" alt="" @click="pagexy()">
    </div>
    <abnormal v-if="abnormalshow" @falsePositivesbtn="abnormalcancel" :acousticimg="acousticimg"
      :acousticdate="acousticdate" />
    <aquatic v-if="aquaticshow" @falsePositivesbtn="aquaticcancel" :diacollectionlist="diacollectionlist" />
    <div class="instructions" v-if="legendshow">
      <div class="databox" v-for="(item, index) in legendlist" :key="index">
        <div class="color" :style="'background:' + item.color"></div>
        <p>{{ item.name }}</p>
      </div>
    </div>
    <!-- <div class="btnboss">
      <el-button class="falsePositivesbtn" type="primary" @click="testfun()">
        测试按钮</el-button>
    </div> -->
  </div>
</template>
<script>
import api from './api'
import axios from 'axios'
import { mapGetters } from 'vuex'
import leftbox from './components/homebox/leftBox'
import rightbox from './components/homebox/rightbox.vue'
// import Menucom from './components/homebox/Menucom.vue'
import diagram from '../menu/diagram.vue'
import environmentSidebar from '../sidebar/environmentSidebar.vue'
import equipmentSidebar from '../sidebar/equipmentSidebar.vue'
import equipmentrightSidebar from '../sidebar/equipmentrightSidebar.vue'
import droneSidebar from '../sidebar/droneSidebar.vue'
import dilogvideo from '../dialog/dilogvideo.vue'
import radar from '../dialog/radar.vue'
import aquatic from '../dialog/aquatic.vue'
import abnormal from '../dialog/abnormal.vue'
import m3u8video from '../dialog/m3u8video.vue'
import fishOut from '../dialog/fishOut.vue'
export default {
  components: {
    leftbox,
    rightbox,
    diagram,
    environmentSidebar,
    equipmentSidebar,
    equipmentrightSidebar,
    droneSidebar,
    dilogvideo,
    radar,
    aquatic,
    abnormal,
    m3u8video,
    fishOut
    // Menucom
  },
  props: {},
  // 计算属性
  computed: {
    ...mapGetters('zsqy', ['legenddata'])
  },
  watch: {
    legenddata: {
      deep: true,
      immediate: true,
      handler(val, old) {
        // console.log(val, '监听数据')
        if (val.length > 0) {
          this.legendshow = true
          this.legendlist = val[0].map
        } else {
          this.legendshow = false
        }
      }
    }
  },
  data() {
    return {
      legendshow: false, // 图例颜色说明
      dilogm3u8videoshow: false,// 视频弹窗
      activeAcousticshow: false,// 主动声学弹窗
      activeAcousticlist: [],// 主动声学数组
      fishOutshow: false,
      op: null, // 3Dshuju
      env: null,
      three: null, // 3D
      listshow: true, // 控制菜单显示
      odermenulistshow: false, // 设备菜单显示
      tulishow: false, // 图例菜单显示
      huanjinshow: false, // 环境菜单显示
      xunhushow: false, // 巡护菜单显示
      terrain: false, // 地形切换显示
      // textshow: false, // 暂无数据显示
      droneshow: false, // 无人机数据显示
      dilogvideoshow: false, // 监控视频弹窗
      radarshow: false, // 雷达弹窗
      searchboxshow: false, // 地名搜索
      abnormalshow: false, // 水生弹窗
      aquaticshow: false, // 水生弹窗

      leftOutAnimation: false, // 左侧淡出
      rightOutAnimation: false, // 右侧淡出
      footerOutAnimation: false, // 列表侧淡出
      odermenuOutAnimation: false, // 设备列表侧淡出
      bottomOutAnimation: false, // 底部侧淡出
      diagramOutAnimation: false, // 图裂侧淡出
      environmentOutAnimation: false, // 环境侧淡出
      equipmentOutAnimation: false, // 设备侧淡出
      equipmentrightOutAnimation: false, // 设备右侧淡出
      droneOutAnimation: false, // 无人机侧淡出

      leftInAnimation: true, // 左侧淡入
      rightInAnimation: true, // 右侧淡入
      footerInAnimation: true, // 列表淡入
      odermenuInAnimation: true, // 设备列表淡入
      bottomInAnimation: true, // 底部淡入
      diagramInAnimation: true, // 图裂淡入
      environmentInAnimation: true, // 环境淡入
      equipmentInAnimation: true, // 设备淡入
      equipmentrightInAnimation: true, // 设备右侧淡入
      droneInAnimation: true, // 无人机淡入

      nowDate: '',
      isAction: -1,
      environmentid: null,
      equipmentid: null,
      footerAction: -1,
      footerlist: [
        {
          menuName: '图例信息'
        },
        {
          menuName: '环境监测'
        },
        {
          menuName: '巡护管理'
        },
        {
          menuName: '科教宣传'
        },
        {
          menuName: '工单管理'
        },
        {
          menuName: '设备管理'
        }
      ], // 底部菜单
      diagramlist: [], // 图例数组
      equipmentlist: [], // 设备管理数组
      patternlist: ['2D', '3D'],
      witchtoplist: [
        {
          img: require('../../assets/homeimg/c2.png'),
          name: '测距离'
        },
        {
          img: require('../../assets/homeimg/c3.png'),
          name: '测面积'
        },
        {
          img: require('../../assets/homeimg/c4.png'),
          name: '初始视角'
        },
        {
          img: require('../../assets/homeimg/c5.png'),
          name: 'GIS切换'
        },
        {
          img: require('../../assets/homeimg/c8.png'),
          name: '地图查询'
        },
        {
          img: require('../../assets/homeimg/c9.png'),
          name: '信息服务'
        },
        {
          img: require('../../assets/homeimg/dian@2x.png'),
          name: '描点开关'
        }
      ],
      curnaverji: -1,
      architecturelist: [
      ],
      twonavshow: false,
      anvshow: null,
      value: '',
      dronename: '视频弹窗',
      dronestyle: {},
      gisnum: 2,
      timenum: null,
      xcoordinates: '',
      ycoordinates: '',
      snC0de: '', // 无人机编号
      placeNames: '', // 地名查询经纬度
      dorshow: 0,
      gispage: true,
      vncurl: '', // vnc远程地址
      sp_url: '', // 雷达视频地址
      sp_MDcode: '', // 雷达视频地址
      m3u8videourl: '',
      m3u8videoname: '',
      m3u8mdcode: null,
      diacollectionlist: [], // 异常弹窗传值
      treedata: [], // 图例树形结构
      treeparentid: null,
      radarnum: 0,
      legendlist: null,
      gisurl: null,
      dronevideoUrl: '',
      acousticimg: '',
      acousticdate: '',
      equirightlist: [],
      m3u8show: 0,
      fishOuturl: '',
      fishOutname: ''
    }
  },
  directives: {
    drag: {
      // 指令的定义
      inserted: function (el) {
        let x = 0
        let y = 0
        let l = 0
        let t = 0
        let isDown = false
        // 鼠标按下事件
        el.onmousedown = function (e) {
          // 获取x坐标和y坐标
          x = e.clientX
          y = e.clientY

          // 获取左部和顶部的偏移量
          l = el.offsetLeft
          t = el.offsetTop
          // 开关打开
          isDown = true
          // 设置样式
          el.style.cursor = 'move'
        }
        // 鼠标移动
        window.onmousemove = function (e) {
          if (isDown === false) {
            return
          }
          // 获取x和y
          const nx = e.clientX
          const ny = e.clientY
          // 计算移动后的左偏移量和顶部的偏移量
          const nl = nx - (x - l)
          const nt = ny - (y - t)

          el.style.left = nl + 'px'
          el.style.top = nt + 'px'
        }
        // 鼠标抬起事件
        el.onmouseup = function () {
          // 开关关闭
          isDown = false
          el.style.cursor = 'default'
        }
      }
    }
  },
  methods: {
    // testfun () {
    //   this.op.GetPatrolHeler().UAVHistoryLoad('894049e0-9491-42a6-99fc-1cb898856529', 5, (res) => { console.log(res) })
    // },
    openimg(val, date) {
      // console.log(val)
      this.abnormalshow = true
      this.acousticimg = val.filename
      this.acousticdate = val.timestamp
    },
    openvideo(val, date) {
      // console.log(val, 'val')
      this.fishOutshow = true
      this.fishOuturl = val.playbackUrl
      this.fishOutname = val.devName
    },
    abnormalcancel() {
      this.abnormalshow = false
    },
    aquaticcancel() {
      this.aquaticshow = false
    },
    aquaticbtn(val) {
      this.aquaticshow = true
      this.diacollectionlist = val
    },
    pagexy() {
      // console.log(this.placeNames.search(','))
      if (this.placeNames.search(',') === -1) {
        axios.get(this.$constant.zsqyUrl + '/zsqy/GisMap/findByAreaName' + '?' + 'areaName=' + this.placeNames, { headers: { database: '11NatureReserves' } })
          .then((res) => {
            // console.log(res, 'res-------')
            if (res.data.data.length === 0) {
              this.$message.warning('不在保护区范围内')
            } else {
              const list = res.data.data[0].coordinate.split(',')
              this.env.SetCenter({
                x: list[0],
                y: list[1]
              })
            }
          })
      } else {
        var list = this.placeNames.split(',')
        // console.log(list)
        var obj = {
          longitude: list[0] * 1,
          latitude: list[1] * 1
        }
        // console.log(obj)
        if (typeof obj.longitude === 'number' && typeof obj.latitude === 'number' && obj.longitude && obj.latitude) {
          if (obj.longitude >= 112.685479 && obj.longitude <= 114.712385 && obj.latitude >= 29.483319 && obj.latitude <= 30.434091) {
            this.env.SetCenter({
              x: list[0],
              y: list[1]
            })
          } else {
            this.$message.warning('不在保护区范围内')
          }
          // this.$message.warning('不在保护区范围内')
        } else {
          this.$message.warning('请输入正确的经纬度')
        }
      }
    },

    isSwitch(checked, event) {
      if (checked) {
        this.env.SwitchDimensional(1)
        this.terrain = true
      } else {
        this.env.SwitchDimensional(0)
        this.terrain = false
        this.env.UnloadTerrain()
      }
    },
    istype(checked, event) {
      if (checked) {
        this.env.LoadTerrain()
      } else {
        this.env.UnloadTerrain()
      }
    },
    // 菜单功能
    witchtopbtn(index) {
      if (index === 0) {
        this.env.EnableDistanceTool()
      } else if (index === 1) {
        this.env.EnableAreaTool()
      } else if (index === 2) {
        this.env.ResetMap()
      } else if (index === 3) {
        // console.log(this.gisnum)
        this.env.SwithBaseMap(this.gisnum)
        this.gisnum += 1
        if (this.gisnum === 4) {
          this.gisnum = 0
        }
      } else if (index === 4) {
        if (this.gispage) {
          this.searchboxshow = !this.searchboxshow
        }
      } else if (index === 5) {
        // const href = 'https://ais.msa.gov.cn' // 网站链接
        window.open('https://ais.msa.gov.cn', '_blank')
      } else if (index === 6) {
        this.env.EnableMarkerTool(true)
      }
    },
    MenufindTree() {
      // api.MenufindTree().then(res => {
      //   this.footerlist = res.data
      // })
      axios.get(this.$constant.zsqyUrl + '/LayersMenu/findTree',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res')
          this.footerlist = res.data.data
        })
    },
    // 设备侧栏子传父方法
    changeequipment(index) {
      // console.log(index)
      this.theLayer()
      this.equipmentid = index
      this.ifoptionid(index)
    },
    ifoptionid(id) {
      if (id === 32) {
        this.op.GetEquipmentHelper().Radar(2, (res) => { console.log(res) })
      } else if (id === 33) {
        this.op.GetEquipmentHelper().Monitor(1, (res) => { console.log(res) })
      } else if (id === 34) {
        this.op.GetEquipmentHelper().WaterEnvironment(3, (res) => { console.log(res) })
      } else if (id === 35) {
        this.op.GetEquipmentHelper().Biotest(4, (res) => { console.log(res) })
      }
    },
    // 监控视频关闭
    dilogvideoclose(index) {
      if (index === 1) {
        this.droneshow = false
      } else {
        this.dilogvideoshow = false
      }
    },
    closem3u8Video() {
      this.dilogm3u8videoshow = false
      this.activeAcousticshow = false
    },
    fishOutVideo() {
      this.fishOutshow = false
    },
    // 监控视频关闭
    radarclose() {
      this.radarshow = false
    },
    // 无人机子传父事件
    clickdronelist(item, index) {
      this.dronename = item.updateTime
      this.dronevideoUrl = '' // 置空视频路径
      this.op.GetPatrolHeler().Dispose()
      if (item.flyState) {
        // if (item.flyState === '运行') {
        this.snC0de = item.snC0de
        // console.log(this.snC0de, 'this.snC0de')
        this.droneshow = true
        // 无人机状态  不等于1和10表示无人机飞行再调用3d接口

        if (item.flyState !== '停止') {
          this.op.GetPatrolHeler().UAVRealtimeLoad(item.snC0de, (res) => { console.log(res) }) // 实时
        }
        // }
      } else {
        this.droneshow = true
        // this.dronevideoUrl = ''
        this.dronevideoUrl = item.videoUrl
        this.op.GetPatrolHeler().UAVHistoryLoad(item.flySign, 10, (res) => { console.log(res) }) // 历史
      }
    },
    // 巡护子传父事件
    clickpatrollist(item, index) {
      // console.log(item, '==')
      this.op.GetPatrolHeler().Dispose()
      if (this.op) { this.op.GetPatrolHeler().Patrol(item.pageNo, item.pageSize, index, (res) => { console.log(res) }) }
    },
    gotoenvironment() {
      // this.$router.push({ name: 'environment' })
      const { href } = this.$router.resolve({
        path: '/visualization/environment'
      })
      window.open(href, '_blank')
    },
    leaveshowfalse() {
      this.twonavshow = false
    },
    // 底部二级菜单
    dianjifal(item, index) {
      // this.environmentClosed()
      this.$store.commit('zsqy/setlegenddata', [])
      console.log(item, 'item')
      this.curnaverji = item.id
      this.timenum = 1 // 停止无人机实时刷新
      this.equirightlist = this.equipmentlist // 点击二级菜单，再去渲染左边下拉框的值
      this.theLayer()
      if (item.id === 66 || item.id === 67) {
        if (item.id === 66) {
          const { href } = this.$router.resolve({
            path: '/visualization/warning'
          })
          window.open(href, '_blank')
        } else if (item.id === 67) {
          const { href } = this.$router.resolve({
            path: '/'
          })
          window.open(href, '_blank')
        }
      } else {
        this.animationClose()
        this.menuClose()
        this.diagramlist = []
        if (item.state === 1) {
          this.tulishow = true
          this.three.Switch(1)
          this.diagramOpen()
          this.treedata.forEach(ele => {
            if (item.id === ele.id) {
              this.$nextTick(() => {
                this.treeparentid = ele.id
              })
            }
          })
        } else {
          axios.get(this.$constant.zsqyUrl + '/LayersMenu/findByParentId' + '?' + 'parentId=' + item.id, { headers: { database: '11NatureReserves' } })
            .then((res) => {
              this.diagramlist = res.data.data
            })
          if (item.parentId === 18) {
            this.huanjinshow = true
            this.three.Switch(2)
            this.environmentOpen()
            this.environmentid = item.id
            // this.equipmentid = null
          } else if (item.parentId === 19) {
            this.xunhushow = true // 控制菜单隐藏
            this.op.GetPatrolHeler().Dispose() // 无人机巡护切换关闭
            this.three.Switch(3)
            this.allcloseSidebar()
            this.droneOpen()

            if (item.id === 68) {
              this.dorshow = 0
              this.timenum = 0
              // this.droneshow = true
            } else if (item.id === 69) {
              this.dorshow = 1
            }
          } else if (item.parentId === 22) {
            // console.log('//////////')
            this.odermenulistshow = true
            this.three.Switch(4)
            this.equipmentrightOpen()
            this.equipmentOpen()
            this.odermenulistOpen()
            this.equipmentid = item.id
            this.ifoptionid(item.id)
          }
        }
      }
    },
    footerbtn(index, item) {
      this.architecturelist = []
      this.footerAction = index
      this.twonavshow = true
      this.droneshow = false

      const weiyi = this.$refs.weiyi

      if (index === 0) {
        this.treedata.forEach(ele => {
          this.architecturelist.push(
            {
              id: ele.id,
              menuName: ele.categoryName,
              state: 1
            }
          )
        })
      } else {
        axios.get(this.$constant.zsqyUrl + '/LayersMenu/findList' + '?' + 'id=' + item.id, { headers: { database: '11NatureReserves' } })
          .then((res) => {
            this.architecturelist = res.data.data
            this.equipmentlist = res.data.data
          })
      }

      // this.three.Release() // 清除图层
      if (index === 0) {
        weiyi.style.left = '1.5%'
        // this.three.Switch(1)
      } else if (index === 1) {
        weiyi.style.left = '14%'
        // this.three.Switch(2)
      } else if (index === 2) {
        weiyi.style.left = '27%'
      } else if (index === 3) {
        weiyi.style.left = '64%'
        // this.three.Switch(0)
        // this.$router.push({ name: 'propaganda' })
        const { href } = this.$router.resolve({
          path: '/visualization/propaganda'
        })
        window.open(href, '_blank')
      } else if (index === 4) {
        weiyi.style.left = '77%'
        // this.three.Switch(0)
      } else if (index === 5) {
        weiyi.style.left = '89%'
        // this.three.Switch(4)
      }
    },
    pageobserytree() {
      api.pageobsery().then(res => {
        // console.log(res, '=///==')
        this.treedata = res.data

        // this.architecturelist = res.data
      })
    },
    gotohome() {
      this.timenum = 1
      // vue.$set(this.timenum)
      this.allclose()
      this.listshow = true
      this.searchboxshow = false
      this.radarnum = this.radarnum + 1
    },

    // 3D引入
    introduce() {
      // console.log(window, '===')
      const Library = window.LAOWANGIS.Library
      // const Library = window.MAPGIS.Library
      // console.log(Library)
      Library.Simpleton(
        {
          key: '5e9BHXJTltLYbA/8ZLWnDEA3gCu039CUdPWdBs0AYxC7YVko0W96OfCn49QMiDYq+2Bg1+vr4GdbN0B6+zLIBXQI3B0aBdaclyBkg1z5F71St/iFV77vZHYAIu+DN8ftEiCsN+xGrT1tRm6i9UV8nzljFUT9j96QjvMlGzCgzWcPVLhs89aaC6usPNGcGTeD3RJgajEwok56WuWd7bsYt5ZGm7biiOYkCVr7y/yY5hTxqx6wKQ2Xgfs2JRi7wFuvN+ARHNPAf18ZHP1gmK9sBXtuYWUWELMGeuMblmFVUCoNjGYwJEe3nZ0KQIsDkHoRZceuLOa/j2zHe7RKFL45HVGzVCGDZN09pBiSJAk0KQfIH4qZY4E/J+6ogWf35fp4JNVL5ScnGdM7xCwkIiJOvg==',
          identify: '长江新螺段白鱀豚国家级自然保护区'
        },
        {
          elementId: 'RenderCanvas',
          assetsURL: {
            static: 'myassets/assets/common',
            model: 'myassets/assets/model',
            icon: 'myassets/assets/icon',

            data: 'myassets/assets/data',
            debug: 'myassets/assets/debug'
          },
          server: {
            // url: 'http://192.168.1.15:8077',
            url: this.$constant.zsqyUrl,
            projectName: '11NatureReserves',
            timerfrequency: 3000
          },
          extendserver: {
            url: process.env.NODE_ENV === 'production'
              ? `http://${window.location.hostname}:81`
              : process.env.VUE_APP_BASE_URL // oa后台服务
            // url: process.env.NODE_ENV === 'production'
            //   ? `http://${window.location.hostname}:81`
            //   : process.env.VUE_APP_BASE_URL
          },
          gis: {
            name: '长江新螺段白鱀豚国家级自然保护区',
            longitude: 113.7,
            latitude: 29.95,
            server: '10.120.44.83:7000', // gis服务
            // server: process.env.NODE_ENV === 'production'
            //   ? `http://${window.location.hostname}:7000`
            //   : process.env.VUE_APP_BASE_URL, // 本地gis服务
            type: 1,
            local: false
          },
          debug: false,
          setting: {
            fpsOnInteracting: 25,
            targetFrameRate: 140
          },
          event: {
            OnReady: () => {
              console.log('%c引擎初始化完成', 'color:red')
              document.oncontextmenu = function (e) {
                // return e.preventDefault()
                // 或者 e.preventDefault()
              }
            },
            onClickModelObservable: (id, name, data) => {
              console.log(name, 'name')
              if (name === '保护区管理处') {
                // const { href } = this.$router.resolve({
                //   path: 'https://www.720yun.com/vr/2522curvq4w'
                // })
                // window.open(href, '_blank')
                window.open('https://www.720yun.com/vr/2522curvq4w', '_blank')
              }
            },
            onClickSpriteObservable: (id, data) => {
              this.dilogvideoshow = false
              console.log(data, 'data')
              if (data.type === 7 && data.data.status === '1') {
                // this.dilogvideoshow = true
                // if (data.data.status === '1') {
                this.dilogm3u8videoshow = true
                this.m3u8videourl = data.data.VideoAddress
                this.m3u8mdcode = data.data.mdcode
                this.m3u8show = 0
                // }
              } else if (data.type === 4 && data.data.status === '1') {
                this.timenum = 0
                this.radarshow = true
                this.vncurl = data.data.vnc
                this.sp_url = data.data.sp_url
                this.sp_MDcode = data.data.MDcode
                this.m3u8videoname = data.name
                // console.log(this.vncurl, data)
                // console.log('开启雷达')
              } else if (data.type === 16 && data.data.status === '1') {
                this.abnormalshow = true
                if (data.data) {
                  this.acousticimg = data.data.filename
                  this.acousticdate = data.data.timestamp
                } else {
                  this.acousticimg = []
                }
              } else if (data.type === 20 && data.data.status === 1) {
                // this.dilogvideoshow = true
                // if (data.data.status === '1') {
                this.activeAcousticshow = true
                this.m3u8videourl = data.data.FLV
                this.m3u8videoname = data.name
                this.m3u8show = 1
                this.pageaactiveAcoustic(data.data.deviceId)
                // }
              }
            },

            onLayerChanged: (type, data) => {
              if (type === 0) {
                if (data === 1) {
                  console.log('%c进入二级图层', 'color:red')
                  this.gispage = false
                  this.searchboxshow = false
                  // this.bottomClosed()
                } else {
                  console.log('%c进入一级图层', 'color:blue')
                  this.gispage = true
                  // this.bottomOpen()
                }
              }
            },
            onCenterChanged: (coordinate) => {
              // console.log(coordinate)
              // console.log(coordinate.x.toFixed(6))
              this.xcoordinates = coordinate.x.toFixed(6)
              this.ycoordinates = coordinate.y.toFixed(6)
            },
            onClickUserMarkerObservable: (id, data, cmd) => {
              // console.log(id, data, '00')

              cmd.Update('SSSS')
              console.log(cmd.Data())
              // cmd.Delete()
              // console.log(cmd.Data())

              cmd.Status(1)
            }
          }
        },

        (res) => {
          this.op = res.op
          this.env = res.env
          this.three = res.three
          // console.log(res, 'r')
        })
    },

    // 底部关闭
    bottomClosed() {
      this.bottomOutAnimation = true
      this.bottomInAnimation = false
    },
    // 底部开启
    bottomOpen() {
      this.bottomOutAnimation = false
      this.bottomInAnimation = true
    },
    // 图层开启
    diagramOpen() {
      this.diagramOutAnimation = false
      this.diagramInAnimation = true
    },
    // 图层关闭
    diagramClosed() {
      this.diagramOutAnimation = true
      this.diagramInAnimation = false
    },
    // 环境开启
    environmentOpen() {
      this.environmentOutAnimation = false
      this.environmentInAnimation = true
    },
    // 环境关闭
    environmentClosed() {
      this.environmentOutAnimation = true
      this.environmentInAnimation = false
    },

    // 设备菜单关闭
    odermenulistClose() {
      this.equipmentClosed()
      this.equipmentrightClosed()
      this.odermenuOutAnimation = true
      this.odermenuInAnimation = false
    },
    // 设备菜单开启
    odermenulistOpen() {
      this.equipmentOpen()
      this.equipmentrightOpen()
      this.odermenuOutAnimation = false
      this.odermenuInAnimation = true
    },
    // 设备开启
    equipmentOpen() {
      this.equipmentOutAnimation = false
      this.equipmentInAnimation = true
    },
    // 设备关闭
    equipmentClosed() {
      this.equipmentOutAnimation = true
      this.equipmentInAnimation = false
    },
    // 设备右侧开启
    equipmentrightOpen() {
      this.equipmentrightOutAnimation = false
      this.equipmentrightInAnimation = true
    },
    // 设备右侧关闭
    equipmentrightClosed() {
      this.equipmentrightOutAnimation = true
      this.equipmentrightInAnimation = false
    },
    // 无人机开启
    droneOpen() {
      this.droneOutAnimation = false
      this.droneInAnimation = true
    },
    // 无人机关闭
    droneClosed() {
      this.droneOutAnimation = true
      this.droneInAnimation = false
    },
    theLayer() {
      this.op.GetCutlineHeler().Dispose() // 3d图例关闭
      this.op.GetEnvHelper().Dispose() // 3d环境监测关闭
      this.op.GetEquipmentHelper().Dispose()// 3d设备管理关闭
    },
    // 关闭所有
    allclose() {
      // this.bottomClosed()
      this.allcloseSidebar()
      this.droneshow = false // 无人机弹窗关闭
      this.dilogvideoshow = false // 视频监控弹窗关闭
      this.odermenulistshow = false // 控制设备菜单隐藏
      this.tulishow = false // 控制设备菜单隐藏
      this.huanjinshow = false // 控制设备菜单隐藏
      this.xunhushow = false // 控制设备菜单隐藏
      // this.env.GetControlsurfaceHeler().Hide() // 3d雷达显示关闭
      // this.clear3d() // 3d图层关闭
      this.op.GetCutlineHeler().Dispose() // 3d图例
      this.op.GetPatrolHeler().Dispose() // 3d无人机关闭
      this.op.GetEnvHelper().Dispose() // 3d环境监测关闭
      this.op.GetEquipmentHelper().Dispose()// 3d设备管理关闭
      this.equipmentid = 0
      this.$store.commit('zsqy/setlegenddata', [])
    },
    // 关闭所有侧栏
    allcloseSidebar() {
      this.diagramClosed()
      this.environmentClosed()
      this.equipmentClosed()
      // this.equipmentrightClosed()
      this.odermenulistClose()
      this.droneClosed()
    },
    menuClose() {
      this.listshow = false // 控制菜单隐藏
      this.odermenulistshow = false // 控制设备菜单隐藏
      this.tulishow = false // 控制设备菜单隐藏
      this.huanjinshow = false // 控制设备菜单隐藏
      this.xunhushow = false // 控制设备菜单隐藏
      // this.textshow = false // 暂无数据隐藏
      this.twonavshow = false // 二级菜单隐藏
      this.dilogvideoshow = false //  监控视频弹窗
    },
    // 关闭左右两侧
    animationClose() {
      this.leftOutAnimation = true
      this.rightOutAnimation = true
      this.footerOutAnimation = true
      this.diagramOutAnimation = true
      this.environmentOutAnimation = true
      this.equipmentOutAnimation = true
      this.equipmentrightOutAnimation = true
      this.droneOutAnimation = true
      this.leftInAnimation = false
      this.rightInAnimation = false
      this.footerInAnimation = false
      this.diagramInAnimation = false
      this.environmentInAnimation = false
      this.equipmentInAnimation = false
      this.equipmentrightInAnimation = false
      this.droneInAnimation = false
      this.$store.commit('zsqy/setfootershow', false)
    },
    // 显示左右两侧
    handleShow() {
      // this.initializeshow = true
      this.leftOutAnimation = false
      this.rightOutAnimation = false
      this.footerOutAnimation = false
      this.bottomOutAnimation = false
      this.leftInAnimation = true
      this.rightInAnimation = true
      this.footerInAnimation = true
      this.bottomInAnimation = true
      this.$store.commit('zsqy/setfootershow', true)
    },
    // 主动声学大小鱼群
    pageaactiveAcoustic (deviceCode) {
      const obj = {
        pageSize: 300,
        pageSum: 1,
        deviceCode: deviceCode
      }
      axios.post(this.$constant.zsqyUrl + '/zsqy/activeAcoustics/findActiveAcousticsByCode', obj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.activeAcousticlist = res.data.data.records
        })
    },
  },
  mounted() {
    this.introduce()
    this.$nextTick(function () {
      setInterval(this.pagetiem, 1000)
    })
  },
  created() {
    console.log(window.location.hostname)
    this.MenufindTree()
    this.bottomClosed()
    this.animationClose()
    this.handleShow()
    this.pageobserytree()
    this.$store.commit('zsqy/setisshow', false)
    document.title = '可视化平台'
  },
  beforeCreate() {
    this.$store.commit('zsqy/setlegenddata', [])
  },
  beforeDestroy() {
  }
}
</script>
<style lang="less" scoped>
ul,
li {
  margin: 0;
  padding: 0;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  // min-height: 1080px;
  overflow-x: hidden;
  display: flex;
  // background: url('../../assets/homeimg/bg.png') 0 0 / 100% no-repeat;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;

  /* Internet Explorer/Edge */
  .debox {
    width: 60%;
    height: 80%;
    background: RGBA(10, 34, 55, 1);
    // border: 2px solid #59a5ff;
    position: absolute;
    top: 10%;
    left: 20%;
    z-index: 3;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .debox_video {
      width: 69%;
      height: 90%;
      background: yellow;
    }

    .debox_list {
      width: 29%;
      height: 90%;
      ul li {
        margin: 0;
        padding: 0;
      }
      .tabbox {
        width: 100%;
        height: 100%;
        // margin-top: 15px;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: auto;
          li {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            //   align-items: center;
            line-height: 40px;
            text-align: center;
            // background: RGBA(56, 74, 97, 0.2);
            p {
              width: 33%;
              color: #fff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            }
          }
          li:hover {
            background: rgba(79, 119, 163, 0.4);
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  #RenderCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .gotoenvironmentbox {
    position: absolute;
    right: 7%;
    top: 13%;
    width: 10%;
    color: #fff;
    font-size: 18px;
    font-family: SourceHanSansCN;
    font-weight: bold;
    cursor: pointer;
    font-size: 20px;
    text-shadow: 0 10 10px #000, 0 20 20px #000, 0 30 30px #000, 0 40 40px #000;
    // background: #00ffe4;
    z-index: 3;
  }

  // .textboxs {
  //   width: 10%;
  //   height: 50px;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   margin: auto;
  //   color: #fff;
  //   font-size: 20px;
  //   text-align: center;
  //   z-index: 2;
  // }
  #droneboxs {
    width: 60%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 24%;
    z-index: 3;
  }

  .patternbox {
    position: absolute;
    top: 3%;
    left: 2%;
    width: 230px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    color: #fff;

    div {
      cursor: pointer;
      width: 110px;
      height: 50px;
      background: url('../../assets/homeimg/2.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 50px;
    }

    div.action {
      background: url('../../assets/homeimg/1.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .searchbox {
    width: 30%;
    height: 53px;
    position: absolute;
    left: 35%;
    top: 14%;
    background: rgba(15, 73, 117, 0.8);
    border-radius: 27px;
    z-index: 2;
    display: flex;
    justify-content: space-around;
    align-items: center;

    /deep/.el-input__inner {
      background-color: transparent;
      border: none;
      color: #bfdafd;
    }

    .el-input {
      width: 80%;
      background: transparent;
      border: none;
    }

    img {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }
  }

  .instructions {
    width: 15%;
    height: 90%;
    // background: #fff;
    overflow-y: auto;

    position: absolute;
    top: 3%;
    right: 2%;
    z-index: 5;
    color: #ffffff;
    font-size: 14px;

    .databox {
      width: 100%;
      // height: 50px;
      display: flex;

      // align-items: center;
      // background: red;
      .color {
        width: 20px;
        height: 20px;
        border-radius: 5px;
      }

      p {
        // line-height: 50px;
        margin-left: 10%;
      }
    }
  }

  .btnboss {
    position: absolute;
    top: 30%;
    right: 30%;
    z-index: 5;
  }

  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background: transparent;
    // background: #fff;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
    // background: #fff;
    //  background: rgba(230, 252, 255, 0.5);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
  }

  /*经纬度*/
  .coordinates {
    width: 18%;
    display: flex;
    justify-content: space-around;
    align-content: center;
    // background: #00ffe4;
    color: #fff;
    position: absolute;
    left: 5%;
    bottom: 0;
    z-index: 2;
  }

  /*侧边栏*/
  .leftBox {
    position: absolute;
    left: 0;
    top: -50px;
    width: 23%;
    height: 99%;
    margin: 22px 0 0 12px;
    padding: 25px 16px 0 13px;
    overflow: hidden;
    z-index: 2;

    .contentbox {
      width: 100%;
      height: 100%;
      background: rgba(8, 39, 55, 0.8);
      border-radius: 10px;
      margin-top: 20px;
    }
  }

  // 左侧同意格式样式
  .sidebarBox {
    position: absolute;
    left: 0;
    top: -50px;
    width: 23%;
    height: 95%;
    margin: 66px 0 0 12px;
    padding: 25px 16px 0 13px;
    background: rgba(8, 39, 55, 0.8);
    z-index: 2;

    .contentbox {
      width: 100%;
      height: 90%;
      border-radius: 10px;
    }
  }

  // 右侧同意格式样式
  .rightsidebarBox {
    position: absolute;
    right: 0;
    top: -50px;
    width: 23%;
    height: 95%;
    margin: 66px 12px 0 0;
    padding: 25px 16px 0 13px;
    background: rgba(8, 39, 55, 0.8);
    z-index: 2;
  }

  .menubox {
    position: absolute;
    left: 24%;
    top: 6%;
    width: 120px;
    z-index: 2;
    user-select: none;

    .switchbox {
      width: 120px;
      height: 45px;
      display: flex;
      justify-content: space-between;

      .switchtop {
        width: 65px;
        height: 65px;

        .minbox {
          width: 65px;
          height: 65px;
          background: url('../../assets/homeimg/c6.png') no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          font-family: SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          margin-top: 5px;
          overflow: hidden;
          cursor: pointer;

          .imgbox {
            width: 27px;
            height: 27px;
            margin-left: 19px;
            margin-top: 10px;
            cursor: pointer;
          }

          p {
            margin-top: 5px;
          }
        }

        .minbox:nth-child(9),
        .minbox:nth-child(10) {
          .imgbox {
            margin-left: 12px;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .rightBox {
    position: absolute;
    right: 0;
    top: -50px;
    width: 23%;
    height: 100%;
    margin: 22px 12px 0 0;
    border-radius: 4px;
    padding: 25px 13px 0 16px;
    overflow: hidden;
    z-index: 2;

    .contentbox {
      width: 100%;
      height: 100%;
      background: rgba(8, 39, 55, 0.8);
      border-radius: 10px;
      margin-top: 20px;
    }
  }

  .bottomBox {
    position: fixed;
    left: 23.5%;
    bottom: 0%;
    width: 53%;
    height: 130px;
    margin: 0 0 10px 0;
    // text-shadow:2px 3px 1px rgba(12, 28, 59, 1);
    text-shadow: 0 10 10px #000, 0 20 20px #000, 0 30 30px #000, 0 40 40px #000;
    border-radius: 4px;
    z-index: 2;
    user-select: none;

    .dh {
      width: 95px;
      height: 95px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;

      .face {
        position: absolute;
        border-radius: 50%;
        border-style: solid;
        animation: animate 3s linear infinite;
      }

      .face:nth-child(1) {
        width: 100%;
        height: 100%;
        color: RGBA(20, 247, 118, 1);
        border-color: currentColor transparent transparent currentColor;
        border-width: 0.2em 0.2em 0em 0em;
        --deg: -45deg;
        animation-direction: normal;
      }

      .face .circle {
        position: absolute;
        width: 50%;
        height: 0.1em;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: rotate(var(--deg));
        // transform-origin: left;
      }

      .face:nth-child(2) {
        width: 90%;
        height: 100%;
        color: gold;
        border-color: currentColor currentColor transparent transparent;
        border-width: 0.2em 0em 0em 0.2em;
        --deg: -135deg;
        animation-direction: reverse;
      }

      @keyframes animate {
        to {
          transform: rotate(1turn);
        }
      }
    }

    .donghuo {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      animation: glow 1200ms ease-out infinite alternate;
    }

    @keyframes glow {
      // 0% {
      //   border-color: #008aa8;
      //   box-shadow: 0 0 5px rgba(0, 141, 170, 0.1),
      //     inset 0 0 5px rgba(0, 141, 170, 0.1), 0 0px 0 #008aa8;
      // }

      // 100% {
      //   border-color: #008aa8;
      //   box-shadow: 0 0 50px rgba(0, 141, 170, 0.5),
      //     inset 0 0 50px rgba(0, 141, 170, 0.3), 0 0 0#008aa8;
      // }
      0% {
        border-color: #00ffe4;
        box-shadow: 0 0 5px rgba(0, 255, 228, 0.1),
          inset 0 0 5px rgba(0, 255, 228, 0.1), 0 0px 0 #00ffe4;
      }

      100% {
        border-color: #00ffe4;
        box-shadow: 0 0 50px rgba(0, 255, 228, 0.5),
          inset 0 0 50px rgba(0, 255, 228, 0.3), 0 0 0#00ffe4;
      }
    }

    .navigation {
      width: 100%;
      height: 130px;
      display: flex;

      div {
        margin-left: 1%;
        width: 12%;
        height: 45px;
        margin-top: 6%;
        text-align: center;
        font-size: 16px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #e6fcff;
        cursor: pointer;
        // background: url('../../assets/homeimg/6666.png') no-repeat;
        background: url('../../assets/homeimg/bt1.png') no-repeat;
        background-size: 100% 100%;

        p {
          width: 100%;
          height: 100%;
          margin-top: 5px;
        }
      }

      div:nth-child(4) {
        margin-left: 25%;
        background: url('../../assets/homeimg/bt2.png') no-repeat;
        background-size: 100% 100%;
      }

      div:nth-child(5) {
        background: url('../../assets/homeimg/bt2.png') no-repeat;
        background-size: 100% 100%;
      }

      div:nth-child(6) {
        background: url('../../assets/homeimg/bt2.png') no-repeat;
        background-size: 100% 100%;
      }

      div.action {
        background: url('../../assets/homeimg/bt2.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .erjinav {
      width: 11%;
      position: absolute;
      left: 0%;
      bottom: 70px;

      ul {
        width: 100%;
        overflow: hidden;

        // background: rgba(12, 28, 59, 0.6);
        li {
          width: 100%;
          height: 50px;
          margin-left: -10%;
          line-height: 50px;
          text-align: center;
          font-size: 14px;
          background: url('../../assets/homeimg/button7.png') no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          color: #c6e7f8;

          p {
            margin-left: 5%;
          }
        }

        // li:hover {
        //   background: #0db9ae;
        // }
        li.active {
          color: #feffff;
        }
      }
    }

    .imgBox {
      width: 25%;
      height: 130px;
      // position: relative;
      position: absolute;
      right: 37%;
      bottom: 0%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  /*侧边栏显示隐藏动画*/
  .leftOutAnim {
    animation: leftOut 1s;
    animation-fill-mode: forwards;
  }

  .rightOutAnim {
    animation: rightOut 1s;
    animation-fill-mode: forwards;
  }

  .footerOutAnim {
    animation: footerOut 1s;
    animation-fill-mode: forwards;
  }

  .bottomOutAnim {
    animation: bottomOut 1s;
    animation-fill-mode: forwards;
  }

  .bottomInAnim {
    animation: bottomIn 1s;
    animation-fill-mode: forwards;
  }

  .leftInAnim {
    animation: leftIn 1s;
    animation-fill-mode: forwards;
  }

  .rightInAnim {
    animation: rightIn 1s;
    animation-fill-mode: forwards;
  }

  .footerInAnim {
    animation: footerIn 1s;
    animation-fill-mode: forwards;
  }

  @keyframes leftOut {
    from {
      left: 0px;
    }

    to {
      left: -30%;
    }
  }

  @keyframes rightOut {
    from {
      right: 0px;
    }

    to {
      right: -30%;
    }
  }

  @keyframes footerOut {
    from {
      left: 25%;
    }

    to {
      left: 2%;
    }
  }

  @keyframes bottomOut {
    from {
      bottom: 0%;
    }

    to {
      bottom: -50%;
    }
  }

  @keyframes bottomIn {
    from {
      bottom: -50%;
    }

    to {
      bottom: 0%;
    }
  }

  @keyframes leftIn {
    from {
      left: -495px;
    }

    to {
      left: 0px;
    }
  }

  @keyframes rightIn {
    from {
      right: -495px;
    }

    to {
      right: 0px;
    }
  }

  @keyframes footerIn {
    from {
      left: 2%;
    }

    to {
      left: 25%;
    }
  }
}
</style>
