<template>
  <div class="details">
    <div class="details-top">
      <TitleBox :titlename="acousticdate" />
      <div class="closebox" @click="falsePositivesbtn()">
        <p class="el-icon-close"></p>
        <p>关闭</p>
      </div>
    </div>
    <div class="details-center">
      <el-image style="width: 100%; height: 100%" :src="imgurl" :preview-src-list="srcList">
      </el-image>
      <!-- <img src="../../assets/homeimg/yitiao.png" alt=""> -->
    </div>
    <!-- <div class="btnbox">
      <el-button size="mini" class="falsePositivesbtn" type="primary" @click="falsePositivesbtn()">
        取消</el-button>
      <el-button size="mini" class="affirm" type="primary">确认</el-button>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'

import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: ['detailsshow', 'acousticimg', 'acousticdate'],
  components: {
    TitleBox
  },
  data () {
    return {
      value: '',
      imgurl: '',
      srcList: [
      ]
    }
  },
  computed: {},
  watch: {
    acousticimg: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.srcList = []
        this.$nextTick(() => {
          // console.log(newvalue, 'zizujianop')
          this.value = newvalue
          this.imgurl = 'http://183.94.86.133:8066/console/api/web/plt?filename=' + newvalue
          this.srcList.push(this.imgurl)
          // this.pageimg()
        })
      }
    },
    acousticdate: {
      immediate: true,
      handler (newvalue, lodvalue) {
        this.srcList = []
        this.$nextTick(() => {
          this.acousticdate = newvalue
        })
      }
    }
  },
  methods: {
    falsePositivesbtn () {
      this.$emit('falsePositivesbtn')
    },
    pageimg () {
      const obj = {
        pageSize: 10,
        pageSum: 1,
        type: null
      }
      axios.post(this.$constant.zsqyUrl + '/zsqy/waterincident/findPageWaterinciddent', obj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          console.log(res, 'res')
          res.data.data.records.forEach(ele => {
            if (this.value === ele.filename) {
              this.imgurl = 'http://183.94.86.133:8066/console/api/web/plt?filename=' + ele.filename
              this.srcList.push(this.imgurl)
            }
          })
          // this.list = res.data.data.records
          // this.total = res.data.data.total

          // this.list.forEach(ele)
          // console.log(this.tableData, this.total, '-=-=')
        })
    }

  },
  created () {

  }
}
</script>
<style lang="less" scoped>
.details {
  width: 50%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 25%;
  // background: url('../../assets/homeimg/k2.png') no-repeat;
  background: #0a2237;
  background-size: 100% 100%;
  overflow: hidden;
  border: 2px solid #59a5ff;
  z-index: 3;
  &-top {
    width: 92%;
    height: 60px;
    padding: 20px;
    margin-left: 4%;
    // border-bottom: 1px solid #6ca0c7;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .closebox {
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
  /deep/.el-input__inner {
    background-color: rgba(83, 118, 161, 0.4);
    color: #bfdafd;
    border: none;
    font-size: 12px;
    border-radius: 0px;
  }

  &-center {
    width: 92%;
    height: 80%;
    // border-bottom: 1px solid #6ca0c7;
    margin-left: 4%;
    font-size: 18px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    .footerbox {
      width: 20%;
      height: 16%;
      margin-left: 10%;
      background: url('../../assets/homeimg/image@2x.png') no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 18px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #fff558;
      overflow: hidden;
      p:nth-child(2) {
        font-size: 14px;
        color: #e6fcff;
        margin-top: -5%;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btnbox {
    width: 100%;
    height: 30%;

    .el-button {
      margin-left: 5%;
      height: 30px;
      margin-top: 5%;
    }
    .falsePositivesbtn {
      border: 1px solid #6da2da;
      background: transparent;
      margin-left: 70%;
    }
  }
}
</style>
